<template>
  <b-card>
    <loader v-if="loading" />
    <ImmobileForm
v-if="form.immobile.id !== ''"
    ref="immobile_form"
    :form-data="form.immobile"
    :external-file-data="externalFileData"
    :publication="true"
    @formDataChanged="formDataChanged" />
    <b-row class="pt-1 mt-2 mr-1" align-h="end">
        <b-col sm="2" class="ml-3">
          <b-button variant="primary" @click="saveForm">Salvar</b-button>
        </b-col>
    </b-row>
    <ImmobileComents v-if="form.immobile.id !== ''" :immobileId="form.immobile.id"/>
  </b-card>
</template>

<script>
import { BRow, BCol, BButton, BCard } from 'bootstrap-vue'
import toast from '@/mixins/toast'
import ImmobileForm from './ImmobileForm.vue'
import ImmobileComents from '@/views/comments/immobile/ImmobileComents'
import Loader from '../components/Loader.vue'
import ImmobileDocumentsService from '@/services/Immobile/ImmobileDocumentsService'
import ImmobilePhotosService from '@/services/Immobile/ImmobilePhotosService'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    ImmobileForm,
    ImmobileComents,
    Loader,
  },
  mixins: [toast],

  data() {
    return {
      loading: false,
      address: {},
      form: {
        immobile: {
          name: '',
          unitNumber: '',
          privateAreaSize: 0,
          suiteCount: 0,
          bedroomCount: 0,
          bathroomCount: 0,
          toiletCount: 0,
          floorCount: 0,
          parkingCount: 0,
          hasServiceArea: true,
          hasCloset: true,
          hasPrivatePool: true,
          hasMaidRoom: true,
          hasBarbecue: true,
          hasMachineryArea: true,
          expectedRemuneration: 0,
          essentialInformation: '',
          quotaCount: 0,
          quotaValue: 0,
          quotaDeadlineSell50: null,
          publishDate: null,
          seem: '',
          situation: 1,
          immobileTypeId: '',
          immobileAddressId: '',
          immobileRatingId: '',
          projectId: '',
          id: '',
          valueReceived: 0,
        },
        immobileAddressStreetName: null,
        immobileDeliberationStatusName: null,
        immobileRatingName: null,
        immobileTypeName: null,
        projectName: '',
      },

      externalFileData: {
        attachments: [],
        bluePrint: [],
        bluePrintAll: [],
        document: [],
      },

      immobileDocumentStatus: [],

      companyId: '7238ed0e-fb91-4c09-b1e3-08d9c3f09918',
      companyAbout: '',

      service: null,
      closet: null,
      piscina: null,
      quartoEmp: null,
      churrasqueira: null,
      maquinas: null,
      projetoObra: null,
      options: [
        { value: true, text: 'Sim' },
        { value: false, text: 'Não' },
      ],
      selectedUf: null,
      listUf: [],
      selectedType: null,
      types: [],
      selectedStatus: null,
      status: [],
      selectedRating: null,
      ratings: [],
      projetos: [
        { value: 'proj01', text: 'Nome do Projeto/Obra 01' },
        { value: 'proj02', text: 'Nome do Projeto/Obra 02' },
        { value: 'proj03', text: 'Nome do Projeto/Obra 03' },
        { value: 'proj04', text: 'Nome do Projeto/Obra 04' },
        { value: 'proj05', text: 'Nome do Projeto/Obra 05' },
      ],
    }
  },

  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },

  mounted() {
    const { id } = this.$route.params.company
    this.getImmobile(id ?? '')
  },

  methods: {
    formDataChanged($event) {
      const ev = $event
      ev.publishDate = ev.publishDate ? ev.publishDate.split('T')[0] : null
      ev.quotaDeadlineSell50 = ev.quotaDeadlineSell50 ? ev.quotaDeadlineSell50.split('T')[0] : null
      this.form.immobile = $event
    },

    externalData() {
      this.externalFileData.attachments = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments
      this.externalFileData.attachmentsDeleted = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachmentsDeleted
    },

    async getImmobile(id) {
      try {
        const p = await this.$http.get(`/api/services/app/Immobiles/GetImmobileForEdit?Id=${id}`)
        const { immobile } = p.data.result
        immobile.publishDate = immobile.publishDate ? immobile.publishDate.split('T')[0] : null
        immobile.quotaDeadlineSell50 = immobile.quotaDeadlineSell50 ? immobile.quotaDeadlineSell50.split('T')[0] : null
        this.form = p.data.result
        this.companyAbout = p.data.result.aboutConstructionCompany
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter dados do imóvel')
      }
      this.getAddress()
    },
    async getAddress() {
      try {
        const p = await this.$http.get(`/api/services/app/ImmobileAddresses/GetImmobileAddressForEdit?id=${this.form.immobile.immobileAddressId}`)
        this.address = p.data.result.immobileAddress
      } catch (e) {
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao obter os dados de endereço do imóvel')
      }
    },

    async registerImmobilePhotos(data) {
      // console.log(data)
      const response = await ImmobilePhotosService.createOrEdit(data)
      if (response.data.error) {
        // this.error = response.data.error.message
        // this.msgError()
      }
    },

    async treaFileAttachments() {

      console.log(this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments)

      const response = this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments.map(res => ({
        name: res.description,
        photo: {
          bytes: res.bytes,
          dataType: res.dataType,
          description: res.description,
        },
        immobileId: this.form.immobile.id,
        id: res.immobilePhotoId,
      }))
      console.log(response)
      return response
    },

    async saveForm() {
      // eslint-disable-next-line no-empty
      this.loading = true
      const { immobile_form } = this.$refs
      const { seem_editor_parecer } = immobile_form.$refs
      const { data_form_external } = immobile_form.$refs
      const { data_essential_information } = data_form_external.$refs

      try {
        this.form.immobile.seem = seem_editor_parecer.content
        this.form.immobile.essentialInformation = data_essential_information.content

        await this.$http.post('/api/services/app/Immobiles/Pulication', this.form.immobile)

        if (this.$refs.immobile_form.$refs.data_form_external.dataFormChild.attachments) {
          const files = await this.treaFileAttachments()
          // eslint-disable-next-line no-restricted-syntax
          for (const file of files) {
            // eslint-disable-next-line no-await-in-loop
            await this.registerImmobilePhotos(file)
          }
        }

        this.msgSuccess('Imóvel publicado com sucesso')
        this.loading = false
      } catch (e) {
        this.loading = false
        if (!this.msgIsOpen()) this.msgError('Houve um erro ao publicar o imóvel')
      }
    },

    // eslint-disable-next-line consistent-return
    async saveImmobileDocuments(data) {
      try {
        const r = (await ImmobileDocumentsService.createOrEdit(data))
        return r
      } catch (e) {
        this.msgError('Houve um erro ao salvar os documentos')
      }
    },

    formatPriceBR(i) {
      let v = i.target.value.replace(/\D/g, '')
      v = `${(v / 100).toFixed(2)}`
      v = v.replace('.', ',')
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
      v = v.replace(/(\d)(\d{3}),/g, '$1.$2,')
      this.formData.expectedRemuneration = v
      return v
    },
  },
}
</script>

<style></style>
